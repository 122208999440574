<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-contacts"></i>
              </span>
                Merchant Account
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Accounts</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Merchant Account</li>
                </ol>
            </nav>
        </div>

        <div v-if="account_status" class="row">
            <div v-if="!account_active" class="col-md-8 grid-margin mx-auto stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Your Merchant Account</h4>
                        <p class="card-description">Pending
                        </p>
                        <form @submit.prevent class="forms-sample">
                            <button type="submit" class="btn btn-gradient-secondary mr-2 disabled">Awaiting Activation</button>
                        </form>
                    </div>
                </div>
            </div>
            <div v-else class="col-md-10 grid-margin mx-auto stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Merchant Account</h4>
                        <p class="card-description">
                            Activated
                        </p>
                        <div class="row">
                            <div class="col-lg-12 grid-margin stretch-card">
                                <div class="card">
                                    <div class="card-body">
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Attribute
                                                </th>
                                                <th>
                                                    Details
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr>
                                                <td class="text-left">
                                                    Name
                                                </td>
                                                <td class="text-left">
                                                    {{ user.name}}
                                                </td>

                                                <td>
                                                    <i class="mdi mdi-eye icon-md disabled"></i><span></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">
                                                    Client Id
                                                </td>
                                                <td v-if="!show.client_id" class="text-left">
                                                    ************************************
                                                </td>
                                                <td  v-else class="text-left">
                                                    {{ account.client_id}}
                                                </td>

                                                <td>
                                                    <i @click="showHidden('client_id')" v-if="!show.client_id" class="mdi mdi-eye icon-md "></i>
                                                    <i @click="showHidden('client_id')" v-else class="mdi mdi-eye-off icon-md"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">
                                                    Client Secret
                                                </td>
                                                <td v-if="!show.client_secret" class="text-left">
                                                    ************************************
                                                </td>
                                                <td v-else class="text-left">
                                                    {{account.client_secret}}
                                                </td>

                                                <td>
                                                    <i @click="showHidden('client_secret')" v-if="!show.client_secret" class="mdi mdi-eye icon-md "></i>
                                                    <i @click="showHidden('client_secret')" v-else class="mdi mdi-eye-off icon-md "></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">
                                                    Password
                                                </td>
                                                <td class="text-left">
                                                    <p v-if="!show.password" class="text-truncate wrap">
                                                        **********************************
                                                    </p>
                                                    <p v-else class="text-truncate wrap">
                                                        {{ account.password }}
                                                    </p>
                                                </td>

                                                <td>
                                                    <i @click="showHidden('password')" v-if="!show.password" class="mdi mdi-eye icon-md "  data-toggle="modal" data-target="#showHiden"></i>
                                                    <i @click="showHidden('password')" v-else class="mdi mdi-eye-off mdi icon-md "></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">
                                                    Token
                                                </td>
                                                <td class="text-left word-wrap">
                                                    <p v-if="!show.token" class="text-truncate wrap">
                                                        **********************************
                                                    </p>
                                                    <form v-if="show.token" @submit.prevent class="forms-sample">
                                                        <div class="form-group">
                                                            <textarea disabled rows="20" v-model="account.token" name="password" type="password" class="form-control" id="password" placeholder="Password">
                                                            </textarea>
                                                        </div>
                                                    </form>
                                                </td>

                                                <td>
                                                    <button @click="getToken" v-if="!account.token " class="btn btn-xs btn-gradient-success"><i v-if="busy_request" class="mdi mdi-spin mdi-loading"></i><span v-else>Request Token</span></button>
                                                    <i @click="showHidden('token')" v-else-if="!show.token" class="mdi mdi-eye icon-md "></i>
                                                    <i @click="showHidden('token')" v-else class="mdi mdi-eye-off mdi icon-md"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-left">
                                                    Refresh Token
                                                </td>
                                                <td class="text-left word-wrap">
                                                    <p v-if="!show.refresh_token" class="text-truncate wrap">
                                                        **********************************
                                                    </p>
                                                    <form v-if="show.refresh_token" @submit.prevent class="forms-sample">
                                                        <div class="form-group">
                                                            <textarea disabled rows="20" v-model="account.refresh_token" name="password" class="form-control" placeholder="Password">
                                                            </textarea>
                                                        </div>
                                                    </form>
                                                </td>

                                                <td>
                                                    <button @click="refreshToken" v-if="!account.refresh_token " class="btn btn-xs btn-gradient-success"><i v-if="busy_request" class="mdi mdi-spin mdi-loading"></i><span v-else>Request Token</span></button>
                                                    <i @click="showHidden('refresh_token')" v-else-if="!show.refresh_token" class="mdi mdi-eye icon-md "></i>
                                                    <i @click="showHidden('refresh_token')" v-else class="mdi mdi-eye-off mdi icon-md"></i>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div v-else class="row">
            <div class="col-md-8 grid-margin mx-auto stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Merchant Account</h4>
                        <p class="card-description">
                            Not Yet Created
                        </p>
                        <form @submit.prevent class="forms-sample">
<!--                            <div class="form-group">-->
<!--                                <label for="password">Username</label>-->
<!--                                <input v-model="password" name="password" type="password" class="form-control" id="password" placeholder="Password">-->
<!--                            </div>-->
                            <button v-if="!busy" @click="createVendorAccount" type="submit" class="btn btn-gradient-primary mr-2">Create Merchant Account</button>
                            <button v-else class="btn btn-gradient-primary mr-2"><i class="mdi mdi-spin mdi-loading"></i></button>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        name: "VendorAccount",
        mounted(){
            this.getAccount()
        },
        computed: {
            user(){
                return this.$store.getters.get_user
            }
        },
        data() {
            return {
                password: '',
                errors: {
                    password: ''
                },
                account_status: false,
                account_active: false,
                account: {
                    token: ''
                },
                show: {
                    client_id: false,
                    client_secret: false,
                    password: false,
                    token: false,
                    refresh_token: false
                },
                status: {

                },

                busy: false,
                busy_request: false,

            }
        },

        methods: {
            getToken(){
                this.busy_request = true;
                axios.get('/login_api_user').then((resp) => {
                    console.log(resp.data);
                    this.account = resp.data;
                    this.busy_request = false;
                })
            },
            refreshToken(){},
            showHidden(value){
                if(value === 'token'){
                    this.show.token = !this.show.token
                }
                else if(value === 'refresh_token'){
                    this.show.refresh_token = !this.show.refresh_token
                }
                else if(value === 'password'){
                    this.show.password = !this.show.password
                }
                else if(value === 'client_id'){
                    this.show.client_id = !this.show.client_id
                }
                else if(value === 'client_secret'){
                    this.show.client_secret = !this.show.client_secret
                }
            },
            getAccount(){
                let that = this
                axios.get('/accounts/show').then((resp) => {
                    that.finish();
                    console.log(resp.data)
                    if(!resp.data.data){
                        this.account_status = false
                    }
                    else {
                        console.log('account approved')

                        this.account_status = true;
                        console.log(resp.data.data)
                        console.log(resp.data.data.client_secret.length > 0)
                        this.account_active = resp.data.data.client_secret.length > 0;
                        this.account = resp.data.data
                    }

                })

            },

            createVendorAccount(){
                this.busy = true;
                axios.post('/accounts').then((resp) => {
                    this.account_status = true;
                }).catch((error) => {

                })
            },


            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },

        },

        created(){
            this.start();
        }
    }
</script>

<style scoped>

    .word-wrap {
        word-wrap: break-word;
    }
</style>