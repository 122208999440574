<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
                All Transactions
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Transactions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">All Transactions</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">

                    <div class="card-body">
                        <h4 class="card-title">Transactions</h4>

<!--                        <button class="btn btn-lg btn-outline-primary float-md-right" data-toggle="modal" data-target="#addTrans">+ Add transaction</button>-->

                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Number
                                </th>
                                <th>
                                    Amount
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Network
                                </th>
                                <th>
                                    type
                                </th>
                                <th>
                                    Created
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="transaction in transactions">
                                <td>
                                    {{transaction.id}}
                                </td>
                                <td>
                                    {{transaction.number}}
                                </td>

                                <td>
                                    {{transaction.amount}} {{transaction.currency}}
                                </td>
                                <td>
                                    <label v-if="transaction.status === 'PENDING'" class="badge badge-gradient-light">PENDING</label>
                                    <label v-else-if="transaction.status === 'SUCCESSFUL'" class="badge badge-gradient-info">SUCCESSFUL</label>
                                    <label v-else class="badge badge-gradient-danger">FAILED</label>
                                </td>

                                <td>
                                    {{transaction.network}}
                                </td>
                                <td>
                                    <label>{{transaction.type}}</label>
                                </td>
                                <td>
                                    <timeago :datetime="transaction.created_at"></timeago>
                                </td>
                                <td>
                                    <i href=""><span class="mdi mdi-pencil icon-sm"></span></i>
                                    <i href=""><span class="mdi mdi-open-in-new icon-sm"></span></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="container align-content-center card-body">
                            <div class="pagination">
                                <a @click="getTransactions(currentPage -1)" :class="{disabled :currentPage === 1}" class="btn btn-gradient-secondary btn-rounded" >&laquo;</a>
                                <a v-for="listItem in pagination" :class="{disabled :(currentPage === listItem || listItem === '...'),  'btn-gradient-primary': currentPage === listItem, 'text-white': currentPage === listItem}" class="btn badge-outline-danger btn-rounded" @click="getTransactions(listItem)">{{ listItem }}</a>
                                <a :class="{disabled :lastPage === currentPage}" @click="getTransactions(currentPage +1)" class="btn btn-gradient-secondary btn-rounded" >&raquo;</a>
                            </div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="addTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add Transaction</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent>
                                            <input v-model="phone_number" type="text" class="form-control" name="" placeholder="Phone number">
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button @click="sendTransaction" type="button" class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        name: "AllTransactions",

        data() {
            return {
                transactions: [],
                phone_number: '',
                pagination: {},
                currentPage: '',
                lastPage: '',

            }
        },
        methods: {
            sendTransaction(){
                axios.post('/request_collection', {
                    phone_number: this.phone_number
                }).then((resp) => {
                    console.log(resp.data);
                })
            },
            paginationMethod(c, m){
                var current = c,
                    last = m,
                    delta = 2,
                    left = current - delta,
                    right = current + delta + 1,
                    range = [],
                    rangeWithDots = [],
                    l;

                for (let i = 1; i <= last; i++) {
                    if (i == 1 || i == last || i >= left && i < right) {
                        range.push(i);
                    }
                }

                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push('...');
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }

                console.log(rangeWithDots)
                this.pagination = rangeWithDots;
                return rangeWithDots;
            },

            getTransactions(page_number){
                this.start()
                axios.get('/transactions/'+ page_number).then((resp) => {
                    console.log(resp.data.data);
                    this.transactions = resp.data.data.data;
                    this.paginationMethod(resp.data.data.current_page, resp.data.data.last_page)
                    this.currentPage =resp.data.data.current_page;
                    this.lastPage = resp.data.data.last_page;
                    this.finish()
                }).catch((error) => {
                    this.fail()
                })
            },

            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },
        },

        created() {
            this.getTransactions(1);
            this.start();
        }
    }
</script>

<style scoped>

</style>