<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
                Withdrawals Transactions
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Transactions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Withdrawals</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">

                    <div class="card-body">
<!--                        <h4 class="card-title float-left">Deposits Transactions</h4>-->

                        <button class="btn btn-lg btn-outline-primary btn-gradient-secondary float-md-right" data-toggle="modal" data-target="#addTrans">+ Make Withdrawal</button>

                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Number
                                </th>
                                <th>
                                    Amount
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Network
                                </th>
                                <th>
                                    type
                                </th>
                                <th>
                                    Created
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="transaction in transactions">
                                <td>
                                    {{transaction.id}}
                                </td>
                                <td>
                                    {{transaction.number}}
                                </td>

                                <td>
                                    {{transaction.amount}} {{transaction.currency}}
                                </td>
                                <td>
                                    <label v-if="transaction.status === 'PENDING'" class="badge badge-gradient-light">PENDING</label>
                                    <label v-else-if="transaction.status === 'SUCCESSFUL'" class="badge badge-gradient-info">SUCCESSFUL</label>
                                    <label v-else class="badge badge-gradient-danger">FAILED</label>
                                </td>
                                <td>
                                    {{transaction.network}}
                                </td>
                                <td>
                                    <label>{{transaction.type}}</label>
                                </td>
                                <td>
                                    <timeago :datetime="transaction.created_at"></timeago>
                                </td>
                                <td>
                                    <i href=""><span class="mdi mdi-pencil icon-sm"></span></i>
                                    <i href=""><span class="mdi mdi-open-in-new icon-sm"></span></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="container align-content-center card-body">
                            <div class="pagination">
                                <a @click="getTransactions(currentPage -1)" :class="{disabled :currentPage === 1}" class="btn btn-gradient-secondary btn-rounded" >&laquo;</a>
                                <a v-for="listItem in pagination" :class="{disabled :(currentPage === listItem || listItem === '...'),  'btn-gradient-primary': currentPage === listItem, 'text-white': currentPage === listItem}" class="btn badge-outline-danger btn-rounded" @click="getTransactions(listItem)">{{ listItem }}</a>
                                <a :class="{disabled :lastPage === currentPage}" @click="getTransactions(currentPage +1)" class="btn btn-gradient-secondary btn-rounded" >&raquo;</a>
                            </div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="addTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Make Withdrawal</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent>
                                            <input v-model="phone_number" type="text" class="form-control" name="" placeholder="Phone number">
                                            <input v-model="amount" type="text" class="form-control" name="" placeholder="Amount">
                                            <select v-model="application" class="form-control form-control-lg" id="currency">
                                                <option>Select Application</option>
                                                <option v-for="application in applications">{{ application.name }}</option>
                                            </select>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button v-if="!busy" @click="sendWithdrawal" type="button" class="btn btn-primary">Submit</button>
                                        <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'
    import swal from "sweetalert";
    export default {
        name: "AllTransactions",

        data() {
            return {
                transactions: [],
                pagination: {},
                currentPage: '',
                lastPage: '',
                phone_number: '',
                application: '',
                application_final: '',
                amount: '',
                applications: [],
                errors: {
                    phone_number: '',
                    application: '',
                    amount: '',
                },
                busy: false,

            }
        },
        watch: {
            application(){
                if(this.application === 'Select Application' || this.application === ''){
                    this.application = 'Select Application';
                    this.application_final = '';
                    console.log('if');
                    this.errors.application = '';
                }
                else{
                    console.log('else');
                    console.log(this.application);
                    this.applications.forEach((application) => {
                        if(application.name === this.application){
                            this.application_final = application.id;
                            console.log(application);

                        }
                    })
                    this.errors.application = '';
                }
            }
        },

        methods: {
            sendWithdrawal(){
                this.busy = true;
                console.log(this.$data);
                axios.post('/request_withdrawal', {
                    phone: this.phone_number,
                    app_id: this.application_final,
                    amount: this.amount
                }).then((resp) => {
                    this.busy = false;
                    console.log(resp.data);
                    this.transactions.unshift(resp.data)
                    swal({
                        title: "Withdrawal!",
                        text: "Withdrawal Pending Approval!",
                        icon: "info",
                        button: "Done!",
                    });
                }).catch((error) => {
                    this.busy = false
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Done!",
                    });
                })
            },

            getTransactions(page_number){
                this.start();
                axios.get('/transactions/disbursements/' + page_number).then((resp) => {
                    console.log(resp.data);
                    this.transactions = resp.data.data.data;
                    this.paginationMethod(resp.data.data.current_page, resp.data.data.last_page)
                    this.currentPage =resp.data.data.current_page;
                    this.lastPage = resp.data.data.last_page;
                    this.finish()
                    this.busy = false;
                }).catch((error) => {
                    this.busy = false;
                    this.finish()
                })
            },
            getApplications(){
                axios.get('/applications/user_apps/').then((resp) => {
                    console.log(resp.data);
                    // this.applications = resp.data.data.data;
                    resp.data.data.data.forEach((application) => {
                        if(application.mtn_secret && application.mtn_secret.length > 0){
                            this.applications.push(application)
                        }
                    })
                    console.log(this.applications);
                })
            },


            paginationMethod(c, m){
                var current = c,
                    last = m,
                    delta = 2,
                    left = current - delta,
                    right = current + delta + 1,
                    range = [],
                    rangeWithDots = [],
                    l;

                for (let i = 1; i <= last; i++) {
                    if (i == 1 || i == last || i >= left && i < right) {
                        range.push(i);
                    }
                }

                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push('...');
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }

                console.log(rangeWithDots)
                this.pagination = rangeWithDots;
                return rangeWithDots;
            },


            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },
        },

        created(){
            this.getTransactions(1);
            this.getApplications();
        }
    }
</script>

<style scoped>

</style>