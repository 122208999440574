<template>
    <div class="content-wrapper">
        <div class="row">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-bell"></i>
              </span>
                Notifications
            </h3>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-9">
                <div class="row">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Recent Notifications</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <i class='mdi mdi-calendar'></i>
                                            </td>
                                            <td>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                </p>
                                                <p class="text-small">8 hours ago</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i class='mdi mdi-calendar'></i>
                                            </td>
                                            <td>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                </p>
                                                <p class="text-small">8 hours ago</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i class='mdi mdi-calendar'></i>
                                            </td>
                                            <td>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                </p>
                                                <p class="text-small">8 hours ago</p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GeneralNotifications"
    }
</script>

<style scoped>

</style>