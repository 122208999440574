import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import UnAuth from "./UnAuth";
import {Vue2Storage} from 'vue2-storage'
import VueTimeago from 'vue-timeago'
import VueProgressBar from 'vue-progressbar'
// axios.defaults.baseURL = 'http://localhost:4001/api/v1/admin-service';
axios.defaults.baseURL = 'https://admin-service.unidevelopers.net/api/v1/admin-service';

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.use(VueProgressBar, {
    color: 'rgb(176,23,33)',
    failedColor: 'red',
    height: '2px'
})
Vue.config.productionTip = false;
Vue.use(Vue2Storage);
// You can pass options
Vue.use(Vue2Storage, {
    prefix: 'uni_admin',
    driver: 'local',
    ttl: 60 * 60 * 24 * 1000 // 24 hours
})
Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: 'en', // Default locale
    // We use `date-fns` under the hood
    // So you can use all locales from it
    locales: {
        'zh-CN': require('date-fns/locale/zh_cn'),
        ja: require('date-fns/locale/ja')
    }
})
let data = '';
new Vue({
    router,
    store,
    created () {
        console.log('getting token');
        data = this.$storage.get('token');
        console.log(data);
        window.hostname = 'foo';
        if(data && data.length !== 0){
            this.$store.dispatch("mutate_state_admin_token", "Bearer "+data.Bearer);
            axios.defaults.headers.common['Authorization'] = "Bearer " +data.Bearer

        }
        else
            this.$store.dispatch("mutate_state_admin_token", "");

        console.log(data);
    },
    render: function (h) {
        if(data){
            console.log('data is here');
            return h(App)
        }
        return h(UnAuth)
    }
}).$mount('#app')
