import Vue from 'vue'
import store from '../store/index'
export default {
    user () {
        return this.$store.state.tokens
    },

    check () {
        console.log('global here');
        let bearer = '';
        new Vue({
            created () {
                // The configuration of the plugin can be changed at any time.
                // Just call the setOptions method and pass the object with the settings to it.
                let token = this.$storage.get('token');

                console.log('in')
                // console.log(token.Bearer)
                if(token){
                    bearer = token.Bearer
                }

            }
        })
        console.log(bearer)
        return bearer.length > 0;
        //get token from localStorage
    }
}