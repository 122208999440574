<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-info text-white mr-2">
                <i class="mdi mdi-application"></i>
              </span>
                Your Applications
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Applications</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Create Application</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-md-8 grid-margin mx-auto stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Merchant Apps</h4>
<!--                        <p class="card-description">-->
<!--                            Not Yet Created-->
<!--                        </p>-->
                        <form @submit.prevent @keydown="errorsClear($event)" class="pt-3">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label v-if="errors.name.length > 0" class="form-text alert-danger" for="name">{{errors.name}}</label>
                                        <input name="name" aria-required="true" v-model="name" type="text" class="form-control form-control-lg" id="name" placeholder="Application Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label v-if="errors.currency.length > 0" class="form-text alert-danger" for="phone">{{errors.currency}}</label>
                                        <select v-model="currency" class="form-control form-control-lg" id="currency">
                                            <option v-for="currency in currencies">{{ currency }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label v-if="errors.app_url.length > 0" class="form-text alert-danger" for="app_url">{{errors.app_url}}</label>
                                        <input v-model="app_url" name="app_url" type="url" validate class="form-control form-control-lg" id="app_url" placeholder="Application URL">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label v-if="errors.callback_url.length > 0" class="form-text alert-danger" for="callback_url">{{errors.callback_url}}</label>
                                        <input v-model="callback_url" name="callback_url" type="url" class="form-control form-control-lg" id="callback_url" placeholder="Callback URL">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label v-if="errors.description.length > 0" class="form-text alert-danger" for="username">{{errors.description}}</label>
                                        <textarea rows="8" v-model="description" name="description" type="text" class="form-control form-control-lg" id="username" placeholder="Application Description"></textarea>
                                    </div>
                                </div>

                            </div>


                            <div v-if="!edit" class="mt-3">
                                <button v-if="!busy_create" @click="createApplication" class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" >Create Application</button>
                                <button v-else class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"><i class="mdi mdi-spin mdi-loading"></i></button>
                            </div>
                            <div v-else class="mt-3">
                                <button v-if="!busy_create" @click="updateApplication" class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" >Update Application</button>
                                <button v-else  class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" ><i class="mdi mdi-spin mdi-loading"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>


</template>

<script>
    import axios from 'axios'
    export default {
        name: "CreateEditApplication",
        mounted(){
            console.log('apps here');
            console.log(this.$route.params.app);
            let app_from = this.$route.params.app;
            if(app_from){
                this.name = app_from.name;
                this.description = app_from.description;
                this.currency = app_from.currency
                this.app_url = app_from.app_url
                this.callback_url = app_from.callback_url
                this.edit = true;


            }

            this.finish()
        },

        data(){
            return {
                name: '',
                description: '',
                currencies: ['Select Currency', 'EUR', 'XAF'],
                currency: 'Select Currency',
                currency_final: '',
                app_url: '',
                callback_url: '',

                errors: {
                    name: '',
                    description: '',
                    currency: '',
                    app_url: '',
                    callback_url: '',
                },
                edit: false,
                busy_create: false,
            }
        },

        watch: {
            currency(){
                if(this.currency === 'Select Currency' || this.currency === ''){
                    this.currency = 'Select Currency';
                    this.currency_final = '';
                    console.log('if');
                    this.errors.currency = '';
                }
                else{
                    console.log('else');
                    this.currency_final = this.currency;
                    this.errors.currency = '';
                }
            }
        },

        methods: {

            createApplication(){
                console.log(this.currency_final
                );
                this.busy_create = true;
                axios.post('/applications', {
                    name: this.name,
                    description: this.description,
                    currency: this.currency_final,
                    app_url: this.app_url,
                    callback_url: this.callback_url
                }).then((resp) => {
                    console.log(resp.data);
                    this.busy_create = false;
                    this.$router.push({ name: 'UsersApplications' })

                }).catch(error => {
                    if(error.response.data.code == '401'){
                        alert('Create Your Merchant Token ')
                    }
                    if(error.response.data.message.name ){
                        this.errors.name = error.response.data.message.name.toString()
                    }
                    if(error.response.data.message.description ){
                        this.errors.description = error.response.data.message.description.toString()
                    }
                    if(error.response.data.message.currency ){
                        this.errors.currency = error.response.data.message.currency.toString()
                    }
                    if(error.response.data.message.app_url ){
                        this.errors.app_url = error.response.data.message.app_url.toString()
                    }
                    if(error.response.data.message.callback_url ){
                        this.errors.callback_url = error.response.data.message.callback_url.toString()
                    }
                    // if(error.response.data.message && !error.response.data.message.username){
                    //     this.errors.username = 'Wrong Credentials '
                    // }
                    this.busy_create = false;

                });

            },
            updateApplication(){
                console.log(this.currency_final
                )
                axios.patch('/applications/'+ this.$route.params.app.id + '/update_details', {
                    name: this.name,
                    description: this.description,
                    currency: this.currency_final,
                    app_url: this.app_url,
                    callback_url: this.callback_url
                }).then((resp) => {
                    console.log(resp.data);
                }).catch(error => {
                    if(error.response.data.message.name ){
                        this.errors.name = error.response.data.message.name.toString()
                    }
                    if(error.response.data.message.description ){
                        this.errors.description = error.response.data.message.description.toString()
                    }
                    if(error.response.data.message.currency ){
                        this.errors.currency = error.response.data.message.currency.toString()
                    }
                    if(error.response.data.message.app_url ){
                        this.errors.app_url = error.response.data.message.app_url.toString()
                    }
                    if(error.response.data.message.callback_url ){
                        this.errors.callback_url = error.response.data.message.callback_url.toString()
                    }
                    // if(error.response.data.message && !error.response.data.message.username){
                    //     this.errors.username = 'Wrong Credentials '
                    // }

                });

            },

            errorsClear(event){
                let name = event.target.name;
                this.errors[name]= '';
            },
            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
        },

        created(){

            this.start()
        }
    }
</script>

<style scoped>

</style>