<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
                Deposits Transactions
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Transactions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Deposits</li>
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">

                    <div class="card-body">
<!--                        <h4 class="card-title float-left">Deposits Transactions</h4>-->

                        <button class="btn btn-lg btn-outline-primary btn-gradient-secondary float-md-right" data-toggle="modal" data-target="#addUrl">+ Create Url</button>
                        <button class="btn btn-lg btn-outline-primary btn-gradient-secondary float-md-right" data-toggle="modal" data-target="#addTrans">+ Make Deposit</button>

                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Number
                                </th>
                                <th>
                                    Amount
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Network
                                </th>
                                <th>
                                    type
                                </th>
                                <th>
                                    Created
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="transaction in transactions">
                                <td>
                                    {{transaction.id}}
                                </td>
                                <td>
                                    {{transaction.number}}
                                </td>

                                <td>
                                    {{transaction.amount}} {{transaction.currency}}
                                </td>
                                <td>
                                    <label v-if="transaction.status === 'PENDING'" class="badge badge-gradient-light">PENDING</label>
                                    <label v-else-if="transaction.status === 'SUCCESSFUL'" class="badge badge-gradient-info">SUCCESSFUL</label>
                                    <label v-else class="badge badge-gradient-danger">FAILED</label>
                                </td>
                                <td>
                                    {{transaction.network}}
                                </td>
                                <td>
                                    <label>{{transaction.type}}</label>
                                </td>
                                <td>
                                    <timeago :datetime="transaction.created_at"></timeago>
                                </td>
                                <td>
                                    <i href=""><span class="mdi mdi-pencil icon-sm"></span></i>
                                    <i href=""><span class="mdi mdi-open-in-new icon-sm"></span></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="container align-content-center card-body">
                            <div class="pagination">
                                <a @click="getTransactions(currentPage -1)" :class="{disabled :currentPage === 1}" class="btn btn-gradient-secondary btn-rounded" >&laquo;</a>
                                <a v-for="listItem in pagination" :class="{disabled :(currentPage === listItem || listItem === '...'),  'btn-gradient-primary': currentPage === listItem, 'text-white': currentPage === listItem}" class="btn badge-outline-danger btn-rounded" @click="getTransactions(listItem)">{{ listItem }}</a>
                                <a :class="{disabled :lastPage === currentPage}" @click="getTransactions(currentPage +1)" class="btn btn-gradient-secondary btn-rounded" >&raquo;</a>
                            </div>
                        </div>
                        <!-- Modal -->
                        <div class="modal fade" id="addTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Request Payment</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form @submit.prevent>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <input id="phone" v-model="phone_number" type="text" class="form-control form-inline" name="phone" placeholder="Phone number">
                                                </div>
                                                <div class="col-md-6">
                                                    <input id="amount" v-model="amount" type="text" class="form-control form-inline" name="" placeholder="Amount">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <select v-model="application" class="form-control form-control-lg" id="application">
                                                        <option>Select Application</option>
                                                        <option v-for="application in applications">{{ application.name }}</option>
                                                    </select>
                                                </div>
<!--                                                <div class="col-md-6">-->
<!--                                                    <select v-model="network" class="form-control form-control-lg" id="network">-->
<!--                                                        <option>Select Network</option>-->
<!--                                                        <option v-for="network in networks">{{ network }}</option>-->
<!--                                                    </select>-->
<!--                                                </div>-->
                                            </div>

                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button v-if="!busy" @click="sendTransaction" type="button" class="btn btn-primary">Submit</button>
                                        <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="addUrl" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="url">Generate Payment Url</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <a v-if="link.length > 0" target="_blank" :href="'/payment-urls/' + link">Payment Url</a>
                                        <form @submit.prevent>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <input id="url_amount" v-model="url_amount" type="text" class="form-control form-inline" name="" placeholder="Amount">
                                                </div>
                                                <div class="col-md-6">
                                                    <select v-model="application" class="form-control form-control-lg" id="application">
                                                        <option>Select Application</option>
                                                        <option v-for="application in applications">{{ application.name }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button v-if="!busy" @click="createPaymentUrl" type="button" class="btn btn-primary">Submit</button>
                                        <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        name: "AllTransactions",

        data() {
            return {
                user_id: '',
                transactions: [],
                pagination: {},
                currentPage: '',
                lastPage: '',
                link: '',
                phone_number: '',
                application: '',
                network: '',
                application_final: '',
                network_final: '',
                amount: '',
                url_amount: '',
                applications: [],
                networks: [
                    'MTN', "ORANGE"
                ],
                errors: {
                    phone_number: '',
                    application: '',
                    amount: '',
                    networks: '',
                    url_amount: '',
                },

                busy: false,

            }
        },
        watch: {
            application(){
                if(this.application === 'Select Application' || this.application === ''){
                    this.application = 'Select Application';
                    this.application_final = '';
                    console.log('if');
                    this.errors.application = '';
                }
                else{
                    console.log('else');
                    console.log(this.application);
                    this.applications.forEach((application) => {
                        if(application.name === this.application){
                            this.application_final = application.id;
                            console.log(application);

                        }
                    })
                    this.errors.application = '';
                }
            },
            network(){
                if(this.network === 'Select Network' || this.network === ''){
                    this.network = 'Select Network';
                    this.network_final = '';
                    console.log('if');
                    this.errors.network = '';
                }
                else{
                    console.log('else');
                    console.log(this.network);
                    this.networks.forEach((network) => {
                        if(network === this.network){
                            this.network_final = network;
                            console.log(network);

                        }
                    })
                    this.errors.network = '';
                }
            }
        },

        methods: {
            sendTransaction(){
                this.busy = true;
                console.log(this.$data);
                axios.post('/request_payment', {
                    phone: this.phone_number,
                    app_id: this.application_final,
                    amount: this.amount
                }).then((resp) => {
                    this.busy = false;
                    $('#addTrans').modal('hide');
                    console.log(resp.data);
                }).catch((error) => {
                    $('#addTrans').modal('hide');
                    this.busy = false
                })

            },
            createPaymentUrl(){
                this.busy = true
                console.log(this.user_id)
                axios.post('/payment_url', {
                    user_id: this.user_id,
                    amount: this.url_amount,
                    app_id: this.application_final,
                }).then((resp) => {
                    this.busy = false
                    this.link = resp.data.url
                    console.log(resp.data)
                }).catch((error) => {
                    // $('#addUrl').modal('hide');
                    this.busy = false
                })
            },
            getTransactions(page_number){
                this.start()

                axios.get('/transactions/collections/' +page_number).then((resp) => {
                    console.log(resp.data);
                    this.transactions = resp.data.data.data;
                    this.paginationMethod(resp.data.data.current_page, resp.data.data.last_page)
                    this.currentPage =resp.data.data.current_page;
                    this.lastPage = resp.data.data.last_page;
                    this.finish()
                }).catch((error) => {
                    this.finish()
                })
            },
            getApplications(){
                axios.get('/applications/user_apps/').then((resp) => {
                    console.log(resp.data);
                    // this.applications = resp.data.data.data;
                    resp.data.data.data.forEach((application) => {
                        if(application.mtn_secret && application.mtn_secret.length > 0){
                            this.applications.push(application)
                        }
                    })
                    console.log(this.applications);
                })
            },
            paginationMethod(c, m){
                var current = c,
                    last = m,
                    delta = 2,
                    left = current - delta,
                    right = current + delta + 1,
                    range = [],
                    rangeWithDots = [],
                    l;

                for (let i = 1; i <= last; i++) {
                    if (i == 1 || i == last || i >= left && i < right) {
                        range.push(i);
                    }
                }

                for (let i of range) {
                    if (l) {
                        if (i - l === 2) {
                            rangeWithDots.push(l + 1);
                        } else if (i - l !== 1) {
                            rangeWithDots.push('...');
                        }
                    }
                    rangeWithDots.push(i);
                    l = i;
                }

                console.log(rangeWithDots)
                this.pagination = rangeWithDots;
                return rangeWithDots;
            },

            getAuthUser(){
                axios.get('/auth_user').then((resp) => {
                    console.log(resp.data.data)
                    this.user_id = resp.data.data.id
                    this.$store.dispatch('mutate_auth_user', resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
            },


            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },
        },

        created(){
            this.getTransactions(1);
            this.getApplications();
            this.getAuthUser();
        }
    }
</script>

<style scoped>

</style>