<template>
    <div id="app" class="container-scroller">
        <!-- partial:partials/_navbar.html -->
        <top-bar></top-bar>
        <!-- partial -->
        <div class="container-fluid page-body-wrapper">
            <!-- partial:partials/_sidebar.html -->
            <side-bar></side-bar>
            <!-- partial -->
            <div class="main-panel">
                <router-view/>
                <!-- content-wrapper ends -->
                <!-- partial:partials/_footer.html -->
                <bottom-bar></bottom-bar>
                <!-- partial -->
            </div>
            <!-- main-panel ends -->
        </div>
        <!-- page-body-wrapper ends -->
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<style lang="scss">
    /*#app {*/
    /*    font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    /*    -webkit-font-smoothing: antialiased;*/
    /*    -moz-osx-font-smoothing: grayscale;*/
    /*    text-align: center;*/
    /*    color: #2c3e50;*/
    /*}*/

    /*.center {*/
    /*    text-align: center;*/
    /*    padding-top: 9px;*/
    /*}*/
    .pagination {
        display: inline-block;
    }
    .pagination a {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        transition: background-color .3s;
        border: 1px solid #ddd;
        margin: 0 4px;
    }
    .pagination a.active {
        background-color: #a25cff;
        color: white;
        border: 1px solid #a25cff;
    }
    .pagination a:hover:not(.active) {background-color: #ddd;}
</style>
<script>
    import TopBar from "./components/generals/TopBar";
    import SideBar from "./components/generals/SideBar";
    import BottomBar from "./components/generals/BottomBar";
    // import '../public/js/chart'
    import axios from 'axios';
    export default {
        components: {BottomBar, SideBar, TopBar},

        mounted() {
            this.javascriptCode()
        },

        methods: {
            javascriptCode(){

                // let plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/js/charts.js"
                // );
                // plugin.async = true;
                // document.head.appendChild(plugin);

                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/js/dashboard.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);

                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/js/file-upload.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);
                //
                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/js/misc.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);
                //
                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/js/off-canvas.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);
                //
                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/vendors/js/vendor.bundle.base.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);
                //
                // plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "/vendors/js/vendor.bundle.addons.js"
                // );
                // plugin.async = true;
                // document.body.appendChild(plugin);
            },
            cssCode(){
                let plugin = document.createElement("link");
                plugin.setAttribute(
                    "href",
                    "/css/style.css"
                );
                plugin.async = true;
                document.body.appendChild(plugin);

            },
            getAuthUser(){
                axios.get('/auth_user').then((resp) => {
                    this.$store.dispatch('mutate_auth_user', resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
            }
        },

        created(){
            console.log('getting token');
            let data = this.$storage.get('token')
            console.log(data);
            this.javascriptCode();
            this.getAuthUser()
            // this.cssCode();
        },
    }
</script>