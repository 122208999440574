<template>
    <div class="col-lg-4 mx-auto">
        <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
                <img src="images/logo-admin.png">
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Sign in to continue.</h6>
            <form @submit.prevent="" class="pt-3" @keydown="errorsClear($event)">
                <div class="form-group">
                    <label v-if="errors.username.length > 0" class="form-text alert-danger" for="username">{{errors.username}}</label>
                    <input name="username" v-model="username" type="" class="form-control form-control-lg" id="username" placeholder="Username, Email, Phone">
                </div>
                <div class="form-group">
                    <label v-if="errors.password.length > 0" class="form-text alert-danger" for="password">{{errors.password}}</label>
                    <input v-model="password" name="password" type="password" class="form-control form-control-lg" id="password" placeholder="Password">
                </div>
                <div class="mt-3">
                    <button v-if="!busy" @click="login" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn">SIGN IN</button>
                    <button v-else class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"><i class="mdi mdi-spin mdi-loading"></i></button>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                        <label class="form-check-label text-muted">
                            <input type="checkbox" class="form-check-input">
                            Keep me signed in
                        </label>
                    </div>
                    <a data-toggle="modal" data-target="#forgottenPassword" href="#" class="auth-link text-black">Forgot password?</a>
                </div>
                <div class="mb-2">
                    <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                        <i class="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                </div>
                <div class="text-center mt-4 font-weight-light">
                    Don't have an account? <router-link to="/register" class="text-primary">Create</router-link>
                </div>
            </form>
        </div>
        <div class="modal fade" id="forgottenPassword" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="url">Forgotten Password</h4>
                        <p>{{message}}</p>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="submitForgotten">
                            <div class="row">
                                <div class="col-md-12">
                                    <input required="required" id="email" v-model="email" type="email" class="form-control form-inline" name="" placeholder="Email">
                                    <label class="form-control" for="email">{{message}}</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button v-if="!busy" @click="submitForgotten" type="button" class="btn btn-primary">Submit</button>
                        <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Login",

        data(){
            return {
                errors: {
                    username: '',
                    password: ""
                },
                username: '',
                password: '',
                busy: false,
                message: '',
            }
        },

        methods: {
            submitForgotten(){
                // if
                this.busy = true;
                axios.post('/forgotten_password', {
                    email: this.email
                }).then((resp) => {
                    this.message = resp.data.data
                    this.busy = false;
                }).catch((error) => {
                    this.busy = false;
                    if(error.response.data.message.email ){
                        this.message = error.response.data.message.email.toString()
                    }
                })
            },
            login(){
                this.busy = true;
                axios({
                    method: 'post',
                    url: '/web_login',
                    data: {
                        username: this.username,
                        password: this.password
                    }
                }).then((resp) => {
                    console.log(resp.data);
                    this.setAuthToken(resp.data.access_token);
                    location.href = "/"
                    this.busy = false;
                }).catch(error => {
                    if(error.response.data.message.username ){
                        this.errors.username = error.response.data.message.username.toString()
                    }
                    if(error.response.data.message.password ){
                        this.errors.password = error.response.data.message.password.toString()
                    }
                    if(error.response.data.message && !error.response.data.message.username){
                        this.errors.username = 'Wrong Credentials '
                    }

                    this.busy = false;
                });
            },
            setAuthToken(token){
                this.$storage.set('token', {
                    Bearer: token
                }, { ttl: 60 * 60 * 1000 })// 24 hours
                const data = this.$storage.get('token')
                // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
                // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

            },

            errorsClear(event){
                let name = event.target.name
                this.errors[name]= ''
            }
        },

        created(){
            this.$storage.remove('token')
        }
    }
</script>

<style scoped>

</style>