<template>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
            <li class="nav-item nav-profile">
                <router-link to="/profile" class="nav-link">
                    <div class="nav-profile-image">
                        <img src="images/faces/face1.jpg" alt="profile">
                        <span class="login-status online"></span> <!--change to offline or busy as needed-->
                    </div>
                    <div class="nav-profile-text d-flex flex-column">
                        <span class="font-weight-bold mb-2">{{user.username}}</span>
                        <span class="text-secondary text-small">{{user.name}}</span>
                    </div>
                    <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                </router-link>
            </li>
            <router-link tag="li" to="/" class="nav-item">
                <a class="nav-link" >
                    <span class="menu-title">Dashboard</span>
                    <i class="mdi mdi-home menu-icon"></i>
                </a>
            </router-link>
            <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#accounts" aria-expanded="false" aria-controls="accounts">
                    <span class="menu-title">Account</span>
                    <i class="menu-arrow"></i>
                    <i class="mdi mdi-contacts menu-icon"></i>
                </a>
                <div class="collapse" id="accounts">
                    <ul class="nav flex-column sub-menu">
                        <li class="nav-item"> <router-link to="/merchant_account" class="nav-link">Merchant Account</router-link></li>
                    </ul>
                </div>
            </li>
            <li v-if="user.status === 'APPROVED'" class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#apps" aria-expanded="false" aria-controls="apps">
                    <span class="menu-title">Applications</span>
                    <i class="menu-arrow"></i>
                    <i class="mdi mdi-application menu-icon"></i>
                </a>
                <div class="collapse" id="apps">
                    <ul class="nav flex-column sub-menu">
<!--                        <li class="nav-item"> <router-link to="/applications" class="nav-link" href="pages/ui-features/buttons.html">All Applications</router-link></li>-->
                        <li class="nav-item"> <router-link to="/users_applications" class="nav-link" href="pages/ui-features/buttons.html">Your Applications</router-link></li>
                        <li class="nav-item"> <router-link to="/create_edit_application" class="nav-link" href="pages/ui-features/buttons.html">Create Application</router-link></li>
                    </ul>
                </div>
            </li>

<!--            <li class="nav-item">-->
<!--                <a class="nav-link" data-toggle="collapse" href="#user" aria-expanded="false" aria-controls="user">-->
<!--                    <span class="menu-title">Users</span>-->
<!--                    <i class="menu-arrow"></i>-->
<!--                    <i class="mdi mdi-account menu-icon"></i>-->
<!--                </a>-->
<!--                <div class="collapse" id="user">-->
<!--                    <ul class="nav flex-column sub-menu">-->
<!--                        <li class="nav-item"> <router-link to="/users" class="nav-link" href="pages/ui-features/buttons.html">All Users</router-link></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </li>-->
            <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#table" aria-expanded="false" aria-controls="table">
                    <span class="menu-title">Transactions</span>
                    <i class="menu-arrow"></i>
                    <i class="mdi mdi-table-large menu-icon"></i>
                </a>
                <div class="collapse" id="table">
                    <ul class="nav flex-column sub-menu">
                        <li class="nav-item"> <router-link to="/transactions" class="nav-link" href="pages/ui-features/buttons.html">All Transactions</router-link></li>
                        <li class="nav-item"> <router-link to="/all_deposits" class="nav-link" href="pages/ui-features/buttons.html">All Deposits</router-link></li>
                        <li class="nav-item"> <router-link to="/all_withdrawals" class="nav-link" href="pages/ui-features/buttons.html">All Withdrawals</router-link></li>
                    </ul>
                </div>
            </li>

        </ul>
    </nav>
</template>

<script>
    export default {
        name: "SideBar",
        computed: {
            user(){
                return this.$store.getters.get_user
            }
        },

        methods: {
            getVendorAccount(){

            },

            createVendorAccount(){

            },

            updateVendorAccount(){

            }
        }
    }
</script>

<style scoped>

</style>