<template>

    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
                Your Profile
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Profile</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Show</li>
                </ol>
            </nav>
        </div>

        <div class="row text-align">
            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Your Details</h4>
                        <p class="card-description">
                            Change Details
                        </p>
                        <form @submit.prevent="update" class="forms-sample">
                            <div class="form-group">
                                <label for="exampleInputUsername1">Username</label>
                                <input disabled v-model="user.username" type="text" class="form-control" id="exampleInputUsername1" placeholder="Username">
                            </div>
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                <input disabled v-model="user.phone" type="text" class="form-control" id="phone" placeholder="Phone">
                            </div>
                            <div class="form-group">
                                <label v-if="errors.name.length > 0" class="form-text alert-danger" for="password">{{errors.name}}</label>
                                <label v-else for="full_name">Name</label>
                                <input v-model="user.name" type="text" class="form-control" id="full_name" placeholder="Name">
                            </div>
                            <div class="form-group">
                                <label v-if="errors.email.length > 0" class="form-text alert-danger" for="password">{{errors.email}}</label>
                                <label v-else for="exampleInputEmail1">Email address</label>
                                <input v-model="user.email" type="email" class="form-control" id="exampleInputEmail1" placeholder="Email">
                            </div>
                            <button v-if="!busy" type="submit" class="btn btn-gradient-primary mr-2">Update</button>
                            <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Password</h4>
                        <p class="card-description">
                            Change Password
                        </p>
                        <form @submit.prevent="changePassword" class="forms-sample">
                            <div class="form-group">
                                <label v-if="errors.old_password.length > 0" class="form-text alert-danger" for="password">{{errors.old_password}}</label>
                                <label v-else for="old_password">Old Password</label>
                                <input v-model="old_password" type="text" class="form-control" id="old_password" placeholder="Old Password">
                            </div>
                            <div class="form-group">
                                <label v-if="errors.password.length > 0" class="form-text alert-danger" for="password">{{errors.password}}</label>
                                <label v-else for="password">Password</label>
                                <input v-model="password" type="text" class="form-control" id="password" placeholder="Password">
                            </div>
                            <div class="form-group">
                                <label v-if="errors.password.length > 0" class="form-text alert-danger" for="password">{{errors.password}}</label>
                                <label v-else for="password_confirmation">Password Confirmation</label>
                                <input v-model="password_confirmation" type="text" class="form-control" id="password_confirmation" placeholder="Password Confirmation">
                            </div>
                            <button v-if="!busy" type="submit" class="btn btn-gradient-primary mr-2">Change Password</button>
                            <button v-else type="button" class="btn btn-primary"><i class="mdi mdi-spin mdi-loading"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import axios from "axios";
    import swal from 'sweetalert';

    export default {
        name: "Edit",
        data(){
            return {
                user: {},
                busy: false,
                old_password: '',
                password: '',
                password_confirmation: '',
                errors: {
                    password: '',
                    old_password: '',
                    email: '',
                    phone: '',
                    name: '',
                }
            }
        },
        computed:{
            userDetails(){
                return this.$store.getters.get_user
            }
        },
        methods: {
            clearErrors(){
                this.errors  = {
                    password: '',
                    old_password: '',
                    email: '',
                    phone: '',
                    name: '',
                }
            },
            changePassword(){
                this.busy = true
                this.clearErrors()
                axios.post('/change_password', {
                    old_password: this.old_password,
                    password: this.password,
                    password_confirmation: this.password_confirmation
                }).then((resp) => {
                    this.busy = false
                    swal({
                        title: "Password!",
                        text: "Password Updated!",
                        icon: "success",
                        button: "Done!",
                    });

                }).catch((error) => {
                    this.busy = false
                    if(error.response.data.message.password ){
                        this.errors.password = error.response.data.message.password.toString()
                    }
                    if(error.response.data.message.old_password ){
                        this.errors.old_password = error.response.data.message.old_password.toString()
                    }

                    if(error.response.data.code && error.response.data.code == '401'){
                        this.errors.old_password = 'Wrong Credentials '
                    }
                })
            },
            update(){
                this.clearErrors()
                this.busy = true
                console.log(this.userDetails)
                axios.patch('/users/' + this.userDetails.id, {
                    name: this.user.name,
                    email: this.user.email,
                }).then((resp) => {
                    console.log(resp.data);
                    this.$store.dispatch('mutate_auth_user', resp.data)
                    this.busy = false
                    swal({
                        title: "Update!",
                        text: "Details Updated!",
                        icon: "success",
                        button: "Done!",
                    });
                }).catch((error) => {
                    this.busy = false

                    if(error.response.data.message.name ){
                        this.errors.name = error.response.data.message.name.toString()
                    }
                    if(error.response.data.message.email ){
                        this.errors.email = error.response.data.message.email.toString()
                    }
                })
            },
            getAuthUser(){
                axios.get('/auth_user').then((resp) => {
                    console.log(resp.data.data)
                    this.user = resp.data.data
                    this.$store.dispatch('mutate_auth_user', resp.data.data)
                }).catch(error => {
                    console.log(error)
                })
            },
        },
        created(){
            this.getAuthUser()
        }
    }
</script>

<style scoped>
    .text-align {
        text-align: left;
    }
</style>