<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-info text-white mr-2">
                <i class="mdi mdi-application"></i>
              </span>
                Your Applications
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Applications</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Your Application</li>
                </ol>
            </nav>
        </div>
        <div v-if="applications && applications.length > 0" class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            <!-- Button trigger modal -->
                            <router-link to="/create_edit_application" class="btn btn-lg btn-outline-info float-right" data-toggle="modal" data-target="#addTrans">+ Add App</router-link>
                        </h4>
                        <div class="table-responsive">
                            <table class="tableApp table table-bordered">
                                <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Currency
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        status
                                    </th>
                                    <th>
                                        URL
                                    </th>
                                    <th>
                                        Callback URL
                                    </th>
                                    <th>
                                        Created at
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="app in applications">
                                    <td>
                                        {{ app.id}}
                                    </td>
                                    <td>
                                        {{app.name}}
                                    </td>
                                    <td>
                                        {{ app.currency }}
                                    </td>
                                    <td>
                                        {{ app.amount }}
                                    </td>
                                    <td>
                                        {{ app.description}}
                                    </td>
                                    <td>
                                        <label v-if="app.mtn_key && app.mtn_key.length > 0" class="badge badge-gradient-info">Activated</label>
                                        <label v-else class="badge badge-gradient-danger">Pending</label>
                                    </td>
                                    <td>
                                        {{ app.app_url}}
                                    </td>
                                    <td>
                                        {{ app.callback_url}}
                                    </td>
                                    <td>
                                        <timeago :datetime="app.created_at"></timeago>
                                    </td>
                                    <td>
                                        <i @click="editApplication(app)" class="mdi mdi-pencil icon-sm "></i><span></span>
                                        <i class="mdi mdi-delete-empty mdi icon-sm text-danger"></i>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
<!--                        <div class="center">-->
<!--                            <div class="pagination">-->
<!--                                <a href="#">&laquo;</a>-->
<!--                                <a href="#">1</a>-->
<!--                                <a href="#" class="active">2</a>-->
<!--                                <a href="#">3</a>-->
<!--                                <a href="#">4</a>-->
<!--                                <a href="#">5</a>-->
<!--                                <a href="#">6</a>-->
<!--                                <a href="#">&raquo;</a>-->
<!--                            </div>-->
<!--                        </div>-->
                        <!-- Modal -->
                        <div class="modal fade" id="addTrans" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Add Transaction</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <form>
                                            <input type="text" class="form-control" name="" placeholder="Phone number">
                                        </form>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Save changes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end to keep -->```
        </div>
        <div v-else class="row">
            <div class="col-md-8 grid-margin mx-auto stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Applications</h4>
                        <p class="card-description">
                            Have No Application Yet
                        </p>
                        <form @submit.prevent class="forms-sample">
                            <router-link to="/create_edit_application" type="submit" class="btn btn-gradient-info mr-2">Create Application</router-link>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        name: "YourApplications",

        data() {
            return {
                applications: [],
            }
        },

        methods: {
            getApplications(){
                axios.get('/applications/user_apps/').then((resp) => {
                    console.log(resp.data);
                    this.applications = resp.data.data.data;
                    console.log(this.applications);
                    this.finish();
                }).catch((error) => {
                    if(error.response.data.code == '401'){
                        alert('Create Your Merchant Token ')
                    }
                })
            },
            editApplication(app){
                this.$router.push({ name: 'CreateEditApplication', params: { app: app } })
            },
            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
        },

        created() {
            this.getApplications();
            this.start();
        }
    }
</script>

<style scoped>

   /*td {*/
   /*     word-break:break-all*/
   /*}*/
</style>