<template>
    <div class="content-wrapper">
        <div class="row">
            <div class="col-lg-12 grid-margin purchase-popup">

                <table class="table table-borderless ">
                    <tbody>
                    <tr>
                        <td class="py-1">
                            <button @click="getDashboardStats('one_day',1)" :class="{active : state_active === 1, 'text-dark' : state_active !== 1}" class="btn btn-sm btn-outline-dark">Today</button>
                        </td>
                        <td>
                            <button @click="getDashboardStats('three_days',3)" :class="{active : state_active === 3, 'text-dark' : state_active !== 3}" class="btn btn-sm btn-outline-dark">3 Days</button>
                        </td>
                        <td>
                            <button @click="getDashboardStats('one_week',7)" :class="{active : state_active === 7, 'text-dark' : state_active !== 7}" class="btn btn-sm btn-outline-dark">1 Week</button>
                        </td>
                        <td>
                            <button @click="getDashboardStats('one_month',30)" :class="{active : state_active === 30, 'text-dark' : state_active !== 30}" class="btn btn-sm btn-outline-dark">1 Month</button>
                        </td>
                        <td>
                            <button @click="getDashboardStats('one_year',365)" :class="{active : state_active === 365, 'text-dark' : state_active !== 365}" class="btn btn-sm btn-outline-dark">1 Year</button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="page-header">
            <h3 class="page-title">
              <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class="mdi mdi-home"></i>
              </span>
                Dashboard
            </h3>
            <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">
                        <span></span>Overview
                        <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="row">
            <div  class=" col-md-4 stretch-card grid-margin">
                <div @click="getGraphStats('transactions', 'Transaction')" class="hand card bg-gradient-danger card-img-holder text-white">
                    <div class="card-body">
                        <img src="images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>
                        <h4 class="font-weight-normal mb-3">Total Transactions
                            <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                        </h4>
                        <h2 class="mb-5">XAF {{statistics.now_total}}</h2>
                        <h6 v-if="statistics.percentage_transactions < 0" class="card-text">Decreased by {{roundToTwo(Math.abs(statistics.percentage_transactions))}}%</h6>
                        <h6 v-else class="card-text">Increased by {{roundToTwo(statistics.percentage_transactions)}}%</h6>

                    </div>
                </div>
            </div>
            <div  class="col-md-4 stretch-card grid-margin">
                <div @click="getGraphStats('collection', 'Deposit')" class=" hand card bg-gradient-info card-img-holder text-white">
                    <div class="card-body">
                        <img src="images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>
                        <h4 class="font-weight-normal mb-3">Total Deposits
                            <i class="mdi mdi-bookmark-plus-outline mdi-24px float-right"></i>
                        </h4>
                        <h2 class="mb-5">XAF {{statistics.now_collection}}</h2>
                        <h6 v-if="statistics.percentage_collection < 0" class="card-text">Decreased by {{roundToTwo(Math.abs(statistics.percentage_collection))}}%</h6>
                        <h6 v-else class="card-text">Increased by {{roundToTwo(statistics.percentage_collection)}}%</h6>

                    </div>
                </div>
            </div>
            <div class="col-md-4 stretch-card grid-margin">
                <div  @click="getGraphStats('disbursement', 'Withdrawal')" class="hand card bg-gradient-success card-img-holder text-white">
                    <div class="card-body">
                        <img src="images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image"/>
                        <h4 class="font-weight-normal mb-3">Total Withdrawals
                            <i class="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                        </h4>
                        <h2 class="mb-5">XAF {{statistics.now_disbursement}}</h2>
                        <h6 v-if="statistics.percentage_disbursement < 0" class="card-text">Decreased by {{roundToTwo(Math.abs(statistics.percentage_disbursement))}}%</h6>
                        <h6 v-else class="card-text">Increased by {{roundToTwo(statistics.percentage_disbursement)}}%</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="clearfix">
                            <h4 class="card-title float-left">{{card_title}}</h4>
                            <div id="visit-sale-chart-legend" class="rounded-legend legend-horizontal legend-top-right float-right"></div>
                        </div>
                        <div id="chart">


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Area chart</h4>
                        <div id="other-stats">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 grid-margin">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Recent Tickets</h4>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Number
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        type
                                    </th>
                                    <th>
                                        Created
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="transaction in statistics.transactions_now">
                                    <td>
                                        {{transaction.id}}
                                    </td>
                                    <td>
                                        {{transaction.number}}
                                    </td>
                                    <td>
                                        <label v-if="transaction.status === 'PENDING'" class="badge badge-gradient-light">PENDING</label>
                                        <label v-else-if="transaction.status === 'SUCCESSFUL'" class="badge badge-gradient-info">SUCCESSFUL</label>
                                        <label v-else class="badge badge-gradient-danger">FAILED</label>
                                    </td>
                                    <td>
                                        <label>{{transaction.type}}</label>
                                    </td>
                                    <td>
                                        <timeago :datetime="transaction.created_at"></timeago>
                                    </td>
                                    <td>
                                        <i href=""><span class="mdi mdi-pencil icon-sm"></span></i>
                                        <i href=""><span class="mdi mdi-open-in-new icon-sm"></span></i>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import axios from "axios"
    export default {
        name: "Dashboard",
        mounted(){
            this.getDashboardStats('three_days', 3)
            this.other_statistics()
        },

        data(){
            return {
                statistics: {},
                state_active: 3,
                day: {
                    1: 2,
                },
                label: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG'],
                pending: [11700, 40, 15, 35, 25, 50, 30, 20],
                success: [10, 10, 30, 40, 25, 50, 15, 30],
                failed: [40, 30, 20, 10, 50, 15, 35, 40],

                max_value_height: 0,
                card_title: 'Transactions',
            }
        },

        computed: {
            tokens(){
                console.log(this.store.getters.get_tokens);
            }
        },

        methods: {
            getGraphStats(link, card_title){
                this.start();
                this.card_title = card_title
                $("#visit-sale-chart").remove();
                $('#chart').append('<canvas id="visit-sale-chart" class="mt-4"></canvas>');
                let url_value = '';
                if(this.state_active === 1){
                    url_value = 'one_day'
                }
                else if(this.state_active === 3){
                    url_value = 'three_days'
                }
                else if(this.state_active === 7){
                    url_value = 'one_week'
                }
                else if(this.state_active === 30){
                    url_value = 'one_month'
                }
                else if(this.state_active === 365){
                    url_value = 'one_year'
                }
                axios.get('/dashboard/transactions/'+ url_value + '_' + link).then((resp) => {
                    console.log(resp.data);
                    this.label = [];
                    this.pending = [];
                    this.success = [];
                    this.failed = [];
                    this.max_value_height = 0;
                    this.calculation(resp.data.data, this.state_active);
                    this.statistics_chart()
                    this.finish();
                })

            },

            getDashboardStats(path, value){
                this.card_title = 'Transactions'
                $("#visit-sale-chart").remove();
                $('#chart').append('<canvas id="visit-sale-chart" class="mt-4"></canvas>');
                this.start();
                this.state_active = value
                console.log('about to role');
                axios.get('/dashboard/transactions/'+ path).then((resp) => {
                    this.statistics = resp.data.data;
                    this.label = [];
                    this.pending = [];
                    this.success = [];
                    this.failed = [];
                    this.max_value_height = 0;
                    console.log('label empty');
                    console.log(value);
                    let stats_id = '';

                    this.calculation(resp.data.data, value);
                    this.statistics_chart();

                    console.log(this.label);
                    this.finish();
                }).catch((error) => {
                    this.finish()
                })
            },

            calculation(data, value){
                if(value === 1){
                    for(let i = 0; i < 24; i++){
                        console.log(i);
                        this.label.push(i + ' hrs');
                    }

                    /**
                     *
                     * Setting Pending values
                     */
                    console.log(data.now_pending)
                    this.pending = []
                    this.success = []
                    this.failed = []
                    for(let i = 0; i < 24; i++){
                        console.log(i);
                        this.pending.push(0);
                        this.success.push(0);
                        this.failed.push(0);
                    }
                    data.now_pending.forEach((stats) => {
                        this.pending[stats.date] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    })

                    /**
                     *
                     * Setting Success Values
                     */
                    console.log(data.now_success)
                    this.success = []
                    for(let i = 0; i < 24; i++){
                        console.log(i);
                        this.success.push(0);
                    }
                    data.now_success.forEach((stats) => {
                        this.success[stats.date] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    })
                    /**
                     *
                     * Setting Failed Values
                     */
                    console.log(data.now_failed)
                    this.failed = []
                    for(let i = 0; i < 24; i++){
                        console.log(i);
                        this.failed.push(0);
                    }
                    data.now_failed.forEach((stats) => {
                        this.failed[stats.date] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    })
                }
                else if(value === 3){
                    let weekdays = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    //Use the getDay() method to get the day.
                    let date = new Date();
                    let day = date.getDay();
                    console.log(day);
                    for(let i = day; i > day-3; i--){
                        if(i < 0){
                            this.label.push(weekdays[7+i]);
                        }
                        else
                            this.label.push(weekdays[i]);
                    }
                    for(let i = 0; i < 3; i++){
                        console.log(i);
                        this.pending.push(0);
                        this.success.push(0);
                        this.failed.push(0);
                    }
                    console.log(this.label);
                    /**
                     *
                     * Success
                     */
                    data.now_success.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.success[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    /**
                     *
                     * Pending
                     */
                    data.now_pending.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.pending[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    console.log(this.pending);
                    /**
                     *
                     * Failed
                     */
                    data.now_failed.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.failed[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });

                    console.log(this.failed);
                }
                else if(value === 7){
                    let weekdays = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    //Use the getDay() method to get the day.
                    let date = new Date();
                    let day = date.getDay();
                    console.log(day);
                    for(let i = day; i > day-7; i--){
                        if(i < 0){
                            this.label.push(weekdays[7+i]);
                        }
                        else
                            this.label.push(weekdays[i]);
                    }
                    for(let i = 0; i < 7; i++){
                        console.log(i);
                        this.pending.push(0);
                        this.success.push(0);
                        this.failed.push(0);
                    }
                    console.log(this.label);
                    /**
                     *
                     * Success
                     */
                    data.now_success.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.success[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    /**
                     *
                     * Pending
                     */
                    data.now_pending.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.pending[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    console.log(this.pending);
                    /**
                     *
                     * Failed
                     */
                    data.now_failed.forEach((stats) => {
                        let date = new Date(stats.date)
                        let day = date.getDay();
                        let index_of = weekdays[day]
                        let day_index = this.label.indexOf(index_of);
                        this.failed[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });

                    console.log(this.failed);
                }
                else if(value === 30){

                    let date = new Date();
                    let year = date.getFullYear();
                    let month = date.getMonth();
                    let day = date.getDate();
                    console.log(month);
                    if(month === 0){
                        month = 12
                    }
                    let month_days = new Date(year, month, 0).getDate();
                    console.log(month_days)
                    for(let i = day; i > day-30; i--){
                        if(i < 0){
                            this.label.push(month_days + i);
                        }
                        else if(i === 0){
                            this.label.push(month_days);

                        }
                        else
                            this.label.push(i);
                    }
                    for(let i = 0; i < 30; i++){
                        console.log(i);
                        this.pending.push(0);
                        this.success.push(0);
                        this.failed.push(0);
                    }

                    data.now_pending.forEach((stats) => {
                        let day_index = this.label.indexOf(stats.date);
                        this.pending[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    data.now_success.forEach((stats) => {
                        let day_index = this.label.indexOf(stats.date);
                        this.success[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    data.now_failed.forEach((stats) => {
                        let day_index = this.label.indexOf(stats.date);
                        this.failed[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });

                }
                else if(value === 365){
                    let weekdays = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    //Use the getDay() method to get the day.
                    let date = new Date();
                    let day = date.getMonth();
                    console.log(day);
                    for(let i = day; i > day-12; i--){
                        if(i < 0){
                            this.label.push(weekdays[12+i]);
                        }
                        else
                            this.label.push(weekdays[i]);
                    }
                    for(let i = 0; i < 12; i++){
                        console.log(i);
                        this.pending.push(0);
                        this.success.push(0);
                        this.failed.push(0);
                    }
                    console.log(this.label);
                    /**
                     *
                     * Success
                     */
                    data.now_success.forEach((stats) => {
                        let month = stats.date -1;
                        let index_of = weekdays[month]
                        let day_index = this.label.indexOf(index_of);
                        this.success[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    /**
                     *
                     * Pending
                     */
                    data.now_pending.forEach((stats) => {
                        let month = stats.date -1;
                        let index_of = weekdays[month]
                        let day_index = this.label.indexOf(index_of);
                        this.pending[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });
                    console.log(this.pending);
                    /**
                     *
                     * Failed
                     */
                    data.now_failed.forEach((stats) => {
                        let month = stats.date -1;
                        let index_of = weekdays[month]
                        let day_index = this.label.indexOf(index_of);
                        this.failed[day_index] = stats.amount
                        if(stats.amount > this.max_value_height){
                            this.max_value_height = stats.amount + 100
                        }
                    });

                    console.log(this.failed);
                }
                this.other_statistics()
            },

            roundToTwo(num) {
                return +(Math.round(num + "e+2")  + "e-2");
            },

            start () {
                this.$Progress.start()
            },
            set (num) {
                this.$Progress.set(num)
            },
            finish () {
                this.$Progress.finish()
            },
            fail () {
                this.$Progress.fail()
            },

            /**
             *
             * Charts
             */
            statistics_chart(){

                Chart.defaults.global.legend.labels.usePointStyle = false;
                let ctx = document.getElementById('visit-sale-chart').getContext("2d");

                let gradientStrokeViolet = ctx.createLinearGradient(0, 0, 0, 181);
                gradientStrokeViolet.addColorStop(0, 'rgba(218, 140, 255, 1)');
                gradientStrokeViolet.addColorStop(1, 'rgba(154, 85, 255, 1)');
                let gradientLegendViolet = 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))';

                let gradientStrokeBlue = ctx.createLinearGradient(0, 0, 0, 360);
                gradientStrokeBlue.addColorStop(0, 'rgba(54, 215, 232, 1)');
                gradientStrokeBlue.addColorStop(1, 'rgba(177, 148, 250, 1)');
                let gradientLegendBlue = 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))';

                let gradientStrokeRed = ctx.createLinearGradient(0, 0, 0, 300);
                gradientStrokeRed.addColorStop(0, 'rgba(255, 191, 150, 1)');
                gradientStrokeRed.addColorStop(1, 'rgba(254, 112, 150, 1)');
                let gradientLegendRed = 'linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))';

                let myChart = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: this.label,
                        datasets: [
                            {
                                label: "Pending",
                                borderColor: gradientStrokeViolet,
                                backgroundColor: gradientStrokeViolet,
                                hoverBackgroundColor: gradientStrokeViolet,
                                legendColor: gradientLegendViolet,
                                pointRadius: 0,
                                fill: false,
                                borderWidth: 1,
                                fill: 'origin',
                                data: this.pending
                            },
                            {
                                label: "Failed",
                                borderColor: gradientStrokeRed,
                                backgroundColor: gradientStrokeRed,
                                hoverBackgroundColor: gradientStrokeRed,
                                legendColor: gradientLegendRed,
                                pointRadius: 0,
                                fill: false,
                                borderWidth: 1,
                                fill: 'origin',
                                data: this.failed
                            },
                            {
                                label: "Success",
                                borderColor: gradientStrokeBlue,
                                backgroundColor: gradientStrokeBlue,
                                hoverBackgroundColor: gradientStrokeBlue,
                                legendColor: gradientLegendBlue,
                                pointRadius: 0,
                                fill: false,
                                borderWidth: 1,
                                fill: 'origin',
                                data: this.success
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        legend: false,
                        legendCallback: function(chart) {
                            let text = [];
                            text.push('<ul>');
                            for (let i = 0; i < chart.data.datasets.length; i++) {
                                text.push('<li><span class="legend-dots" style="background:' +
                                    chart.data.datasets[i].legendColor +
                                    '"></span>');
                                if (chart.data.datasets[i].label) {
                                    text.push(chart.data.datasets[i].label);
                                }
                                text.push('</li>');
                            }
                            text.push('</ul>');
                            return text.join('');
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    display: true,
                                    // min: 0,
                                    // stepSize: 100,
                                    max: this.max_value_height
                                },
                                gridLines: {
                                    drawBorder: false,
                                    color: 'rgba(235,237,242,1)',
                                    zeroLineColor: 'rgba(235,237,242,1)'
                                }
                            }],
                            xAxes: [{
                                gridLines: {
                                    display:false,
                                    drawBorder: false,
                                    color: 'rgba(0,0,0,1)',
                                    zeroLineColor: 'rgba(235,237,242,1)'
                                },
                                ticks: {
                                    padding: 20,
                                    fontColor: "#9c9fa6",
                                    autoSkip: true,
                                },
                                categoryPercentage: 0.5,
                                barPercentage: 0.5
                            }]
                        }
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    }
                })
                $("#visit-sale-chart-legend").html(myChart.generateLegend());
            },

            other_statistics(){
                $("#areachart-multi").remove();
                $('#other-stats').append('<canvas id="areachart-multi" class="chartjs-render-monitor"></canvas>');
                let multiAreaData = {
                    labels: this.label,
                    datasets: [{
                        label: 'Failed',
                        data: this.failed,
                        borderColor: ['rgba(255, 99, 132, 0.5)'],
                        backgroundColor: ['rgba(255, 99, 132, 0.5)'],
                        borderWidth: 1,
                        fill: true
                    },
                        {
                            label: 'Success',
                            data: this.success,
                            borderColor: ['rgba(54, 162, 235, 0.5)'],
                            backgroundColor: ['rgba(54, 162, 235, 0.5)'],
                            borderWidth: 1,
                            fill: true
                        },
                        {
                            label: 'Pending',
                            data: this.pending,
                            borderColor: ['rgba(255, 206, 86, 0.5)'],
                            backgroundColor: ['rgba(255, 206, 86, 0.5)'],
                            borderWidth: 1,
                            fill: true
                        }
                    ]
                };

                let multiAreaOptions = {
                    plugins: {
                        filler: {
                            propagate: true
                        }
                    },
                    elements: {
                        point: {
                            radius: 2
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: true,
                                // min: 0,
                                // stepSize: 20,
                                max: this.max_value_height
                            },
                        }]
                    }
                }

                let multiAreaCanvas = $("#areachart-multi").get(0).getContext("2d");
                let multiAreaChart = new Chart(multiAreaCanvas, {
                    type: 'line',
                    data: multiAreaData,
                    options: multiAreaOptions
                });
            }
        },
        created(){
            // this.$storage.remove('token')
            console.log('we created');
        }
    }
</script>

<style scoped>

    .hand {
        cursor: pointer;
    }
</style>