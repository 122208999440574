import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/Dashboard";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import AllUsers from "../views/users/AllUsers";
import Middleware from "../services/middleware";
import auth from "../services/auth"
import {Vue2Storage} from 'vue2-storage'
import AllTransactions from "../views/transactions/AllTransactions";
import AllAccounts from "../views/accounts/AllAccounts";
import VendorAccount from "../views/accounts/VendorAccount";
import YourApplications from "../views/Applications/YourApplications";
import CreateEditApplication from "../views/Applications/CreateEditApplication";
import DepositsTransactions from "../views/transactions/DepositsTransactions";
import WithdrawalTransactions from "../views/transactions/WithdrawalTransactions";
import GeneralNotifications from "../views/notifications/GeneralNotifications";
import PaymentPag from "../views/transactions/PaymentPag";
import Edit from "../views/profile/Edit";
// import NProgress from 'ngprogress'
Vue.use(VueRouter, Vue2Storage)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: Middleware.auth,
        title: 'Uni Admin - Dashboard',
        meta: {
            title: 'Dashboard'
        },
        metaTags: [
            {
                name: 'Dashboard',
                content: 'The home page of our example app.'
            },
            {
                property: 'og:description',
                content: 'The home page of our example app.'
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: Middleware.guest,
        meta: {
            title: 'Login'
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        beforeEnter: Middleware.guest,
        meta: {
            title: 'Registration'
        },
    },
    {
        path: '/users',
        name: 'Users',
        component: AllUsers,
        beforeEnter: Middleware.auth
    },
    {
        path: '/payment-urls/:payment_url',
        name: 'PaymentUrl',
        component: PaymentPag,
        // beforeEnter: Middleware.auth,
        meta: {
            title: 'WebPayment'
        },
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: AllTransactions,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Transactions'
        },
    },
    {
        path: '/all_deposits',
        name: 'Deposits',
        component: DepositsTransactions,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Deposits'
        },
    },
    {
        path: '/all_withdrawals',
        name: 'Withdrawal',
        component: WithdrawalTransactions,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Withdrawals'
        },
    },
    {
        path: '/merchant_account',
        name: 'VendorAccount',
        component: VendorAccount,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Merchant Account'
        },
    },
    {
        path: '/users_applications',
        name: 'UsersApplications',
        component: YourApplications,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Your Applications'
        },
    },
    {
        path: '/create_edit_application',
        name: 'CreateEditApplication',
        component: CreateEditApplication,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Create Or Edit Application'
        },
    },
    {
        path: '/general_notifications',
        name: 'GeneralNotification',
        component: GeneralNotifications,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'General Notifications'
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Edit,
        beforeEnter: Middleware.auth,
        meta: {
            title: 'Profile'
        },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/About.vue')
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

// router.beforeEach((to, from, next) => {
//     // if (auth.check && auth.check.length === 0) next('/login')
//     // else next()
//     next()
// })

// router.beforeResolve((to, from, next) => {
//     // If this isn't an initial page load.
//     if (to.name) {
//         // Start the route progress bar.
//         NProgress.start()
//     }
//     next()
// })
//
// router.afterEach((to, from) => {
//     // Complete the animation of the route progress bar.
//     NProgress.done()
// })


export default router
