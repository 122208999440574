<template>
    <div class="col-lg-8 mx-auto">
        <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
                <img src="/images/logo-admin.png">
            </div>
            <h4>New here?</h4>
            <h6 class="font-weight-light">Signing up is easy. It only takes a few steps</h6>
            <form @submit.prevent="register" @keydown="errorsClear($event)" class="pt-3">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label v-if="errors.name.length > 0" class="form-text alert-danger" for="email">{{errors.name}}</label>
                            <input v-model="name" name="name" type="text" class="form-control form-control-lg" id="name" placeholder="Name">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label v-if="errors.username.length > 0" class="form-text alert-danger" for="username">{{errors.username}}</label>
                            <input v-model="username" name="username" type="text" class="form-control form-control-lg" id="username" placeholder="Username">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label v-if="errors.phone.length > 0" class="form-text alert-danger" for="phone">{{errors.phone}}</label>
                            <input v-model="phone" name="phone" type="text" class="form-control form-control-lg" id="phone" placeholder="Phone">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label v-if="errors.email.length > 0" class="form-text alert-danger" for="email">{{errors.email}}</label>
                            <input v-model="email" name="email" type="email" validate class="form-control form-control-lg" id="email" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label v-if="errors.password.length > 0" class="form-text alert-danger" for="password">{{errors.password}}</label>
                            <input v-model="password" name="password" type="password" class="form-control form-control-lg" id="password" placeholder="Password">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input v-model="password_confirmation" name="password_confirmation" type="password" class="form-control form-control-lg" id="password_confirmation" placeholder="Password Confirmation">
                        </div>
                    </div>
                </div>


                <div class="mb-4">
                    <div class="form-check">
                        <label class="form-check-label text-muted">
                            <input type="checkbox" class="form-check-input">
                            I agree to all Terms & Conditions
                        </label>
                    </div>
                </div>
                <div class="mt-3">
                    <button v-if="status" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" >SIGN UP</button>
                    <a v-else class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn text-white" > <i class="mdi mdi-loading mdi-spin"></i></a>
                </div>
                <div class="text-center mt-4 font-weight-light">
                    Already have an account? <router-link to="/login" href="login.html" class="text-primary">Login</router-link>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "Register",

        data(){
            return {
                errors: {
                    name: "",
                    username: '',
                    email: "",
                    phone: "",
                    password: "",
                    password_confirmation: ""
                },
                name: "",
                username: '',
                email: "",
                phone: "",
                password: '',
                password_confirmation: "",
                status: true,
                busy: false,
            }
        },

        methods: {
            register(){
                this.status = false;
                axios({
                    method: 'post',
                    url: '/web_register',
                    data: {
                        name: this.name,
                        username: this.username,
                        email: this.email,
                        phone: this.phone,
                        password: this.password,
                        password_confirmation: this.password_confirmation
                    }
                }).then((resp) => {
                    console.log(resp.data);
                    this.setAuthToken(resp.data.access_token);
                    location.href = "/"
                }).catch(error => {
                    this.status = true;
                    if(error.response.data.message.name ){
                        this.errors.name = error.response.data.message.name.toString()
                    }
                    if(error.response.data.message.username ){
                        this.errors.username = error.response.data.message.username.toString()
                    }
                    if(error.response.data.message.email ){
                        this.errors.email = error.response.data.message.email.toString()
                    }
                    if(error.response.data.message.phone ){
                        this.errors.phone = error.response.data.message.phone.toString()
                    }
                    if(error.response.data.message.password ){
                        this.errors.password = error.response.data.message.password.toString()
                    }
                    if(error.response.data.message.password_confirmation ){
                        this.errors.password_confirmation = error.response.data.message.password_confirmation.toString()
                    }
                    // if(error.response.data.message && !error.response.data.message.username){
                    //     this.errors.username = 'Wrong Credentials '
                    // }

                });
            },
            setAuthToken(token){
                this.$storage.set('token', {
                    Bearer: token
                }, { ttl: 60 * 60 * 1 * 1000 })// 24 hours
                const data = this.$storage.get('token')
                // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
                // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

            },

            errorsClear(event){
                let name = event.target.name
                this.errors[name]= ''
            }
        }
    }
</script>

<style scoped>

</style>