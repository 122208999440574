<template>
    <div class="col-lg-4 mx-auto">
        <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
                <img src="/images/logo-admin.png">
            </div>
            <h4>Hello! Complete Your Payment</h4>
            <h6 class="font-weight-light">Input Your Number.</h6>
            <form @submit.prevent @keydown="errorsClear($event)" class="pt-3">
                <div class="card">
                    <div class="card-body ca align-content-center">
                        <div class="row">
                            <div v-if="busy" class="col-lg-12">
                                <p>Please Check Your Phone and Approve Transaction</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label v-if="errors.phone.length > 0" class="form-text alert-danger" for="name">{{errors.phone}}</label>
                                    <input name="name" aria-required="true" v-model="phone" type="text" class="form-control form-control-lg" id="name" placeholder="Phone Number">
                                </div>
                            </div>

                        </div>


                        <div class="mt-3">
                            <button v-if="!busy" @click="payBill" class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn" >Pay</button>
                            <button v-else class="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"><i class="mdi mdi-spin mdi-loading"></i></button>
                        </div>

                    </div>
                </div>
            </form>
        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    export default {
        name: "PaymentPag",
        data(){
            return {
                phone: '',
                logo: '',
                amount: '',
                errors: {
                    phone: '',
                },
                busy: false,
            }
        },
        methods: {
            payBill(){
                this.busy = true
                axios.post('/payment_url/use/'+ this.$route.params.payment_url, {
                    phone: this.phone
                }).then((resp) => {
                    this.busy = false
                    console.log(resp.data);
                }).catch((error) => {
                    this.busy = false
                    if(error.response.data.message.phone ){
                        this.errors.phone = error.response.data.message.phone.toString()
                    }
                    else {
                        this.errors.phone = error.response.data.message
                    }
                    console.log(error)
                })
            },

            errorsClear(){
                this.errors = {
                    phone: '',
                }
            }
        }
    }
</script>

<style scoped>

</style>