<template>

    <div id="app" class="container-scroller">
        <div class="container-fluid page-body-wrapper full-page-wrapper">
            <div class="content-wrapper d-flex align-items-center auth">
                <div class="row w-100">
                    <router-view/>
                </div>
            </div>
            <!-- content-wrapper ends -->
        </div>
        <!-- page-body-wrapper ends -->
    </div>
</template>

<script>

    export default {
        components: {},

        mounted(){
            this.javascriptCode()
        },

        data(){
            return {

            }
        },

        methods: {
            javascriptCode(){

                let plugin = document.createElement("script");
                // plugin.setAttribute(
                //     "src",
                //     "js/charts.js"
                // );
                // plugin.async = true;
                // document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/js/dashboard.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/js/file-upload.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/js/misc.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/js/off-canvas.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/vendors/js/vendor.bundle.base.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);

                plugin = document.createElement("script");
                plugin.setAttribute(
                    "src",
                    "/vendors/js/vendor.bundle.addons.js"
                );
                plugin.async = true;
                document.head.appendChild(plugin);
            },

        },

        created(){

        }
    }
</script>

<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #2c3e50;

            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }
</style>
