<template>
    <div class="content-wrapper">
        <div class="page-header">
            <h3 class="page-title">
                All Users
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page">Users</li>
<!--                    <li class="breadcrumb-item active" aria-current="page">All Users</li>-->
                </ol>
            </nav>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th>
                                    # Id
                                </th>
                                <th>
                                    Full Name
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Created On
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="user in users">
                                <td>
                                    {{user.id}}
                                </td>
                                <td>
                                    {{ user.username}}
                                </td>
                                <td>
                                    <label v-if="user.status === 'PENDING'" class="badge badge-danger">Pending</label>
                                    <label v-else-if="user.status === 'APPROVED'" class="badge badge-gradient-success">Activated</label>
                                </td>

                                <td>
                                    <timeago :datetime="user.created_at"></timeago>
                                </td>
                                <td>
                                    <i class="mdi mdi-account-edit icon-md "></i><span></span>
                                    <i class="mdi mdi-delete-empty mdi icon-md text-danger"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "AllUsers",

        data() {
            return {
                users: [],
            }
        },

        created(){
            axios.get('/users').then((resp) => {
                console.log(resp.data);
                this.users = resp.data.data;
            })
        }
    }
</script>

<style scoped>

</style>