import auth from './auth'

export default {
    auth (to, from, next) {

        if(auth.check()){
            next()
        }
        else
            location.href = '/login';
            // next('/login');
    },

    guest (to, from, next) {
        if(!auth.check()){
            next()
        }
        else
            next('/');
    }
}