import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tokens: {
            admin: ''
        },
        user: {},
    },
    mutations: {
        set_state_admin_token(state, token){
            state.tokens.admin = token;
        },

        set_auth_user(state, user){
            state.user = user
        }
    },
    actions: {
        mutate_state_admin_token(context, token){
            context.commit("set_state_admin_token", token)
        },
        mutate_auth_user(context, user){
            context.commit("set_auth_user", user)
        }
    },
    modules: {
    },
    getters: {
        get_tokens(state){
            return state.tokens;
        },

        get_user(state){
            return state.user
        }
    }
})
